<template>
  <div class="max-w-4xl mx-auto px-2 z-0 p-2 rounded">
    <!-- <div class="message">{{ formMessage }}</div> -->
    <div>
      <form @submit.prevent="findUser">
        <div
          class="w-full md:grid md:grid-cols-12 gap-8 mx-auto bg-white md:h-96 p-4 rounded-t-md"
        >
          <div class="col-span-6 flex items-center">
            <img src="../../../assets/login.gif" />
          </div>
          <div
            class="col-span-6 flex flex-col justify-center gap-8 mt-8 md:mt-0"
          >
            <h2 class="text-2xl text-brand font-semibold">Join With GCPSC</h2>
            <div class="relative">
              <Input
                id="phone"
                class="w-full border focus:outline-none text-center margin: 0"
                type="number"
                v-model="phone"
                placeholder="Phone : 017XXXXXXXX"
                required
              />
              <!-- <show-error
                            :errors="formErrors"
                            name="phone"
                            class="w-full max-w-xs mx-auto"
                        /> -->
            </div>

            <div class="block w-full relative" v-if="searchUser.step">
              <Input
                type="text"
                v-model="name"
                :class="
                  searchUser.step && searchUser.hasName
                    ? 'border-green-700/10 text-green-700'
                    : 'border-brand-primary text-brand-primary'
                "
                class="w-full focus:outline-none text-center"
                placeholder="Full Name"
                autocomplete="off"
              />
              <div
                v-if="searchUser.step && searchUser.hasName"
                class="absolute inset-0 bg-green-700/10 z-50 flex items-center justify-end px-4"
              >
                <span class="text-green-700 text-2xl">&#10003;</span>
              </div>
            </div>
            <!-- <div class="block w-full" v-if="searchUser.step">
                        <Input
                            v-model="password"
                            class="w-full focus:outline-none text-center"
                            placeholder="Password"
                            autocomplete="off"
                        />
                    </div>
                    <div class="w-full flex justify-start items-center">
                        <label for="sms_input" class="flex items-center gap-2 text-md md:text-xl hover:underline">
                            <input id="sms_input" type="checkbox" v-model="sms" class="hidden w-5 h-5" />
                            I don't remember my password
                        </label>
                     </div> -->
            <div class="flex justify-center">
              <button
                :class="{
                  'opacity-50 cursor-not-allowed': checkDisabled,
                }"
                :disabled="checkDisabled"
                type="submit"
                class="bg-brand text-white py-1.5 px-2 rounded-md flex items-center"
              >
                <spinner v-if="loading" />
                Join
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Input from "../../../components/input-new.vue";
import Spinner from "../../../components/spinner.vue";
// import ShowError from "../../../components/show-error.vue";
export default {
  components: {
    Input,
    Spinner,
  },
  data() {
    return {
      login: false,
      send: false,
      sms: false,
      name: "",
      phone: "",
      step: 1,
      loading: false,
    };
  },
  computed: {
    searchUser() {
      return this.$store.state.join.user;
    },
    checkDisabled() {
      return this.phone.length !== 11;
    },
  },
  watch: {
    "$store.state.join.user"(data) {
      (this.phone = data.phone), (this.name = data.name);
    },
  },
  methods: {
    findUser() {
      this.loading = true;
      this.formErrors = null;

      if (this.phone.length == 11) {
        this.$store
          .dispatch("join/findUser", {
            data: {
              phone: this.phone,
            },
          })
          .then((response) => {
            const data = this.searchUser;
            if (data.has_user) {
              this.$router.push({
                path: "/login",
                query: { phone: this.phone },
              });
            }
            if (!data.has_user) {
              this.$router.push({
                path: "/confirm",
                query: { phone: this.phone },
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
